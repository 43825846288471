<section class="article-component-cont">
    <header>
      <h3 class="ent-color"><i class="fas fa-file" aria-hidden="true"></i>Requirements</h3>
    </header>
    <div class="article-content requirement">
      <ent-alert-message type="info" *ngIf="(requirementData && requirementData.length == 0 || requirementData == null) && !restrictChaseUser">{{ message.requirementNotAvailable }}</ent-alert-message>
      <ent-alert-message type="warning" *ngIf="showWarningMessage && !restrictChaseUser">{{ message.outstandingRequirementsSubmitInfo }}</ent-alert-message>
      <ent-alert-message type="info" *ngIf="showInfoMessage && !restrictChaseUser">{{ message.outstandingRequirementsInfo }}</ent-alert-message>
      <!-- accordion start -->
      <ent-accordion (stateChange)="stateChange($event)" [autoScroll]="false">
        <!-- panel for outstanding requirements -->
        <ent-panel title="Outstanding requirements ({{ outstandingTableConfig.data.length }})" [opened]="accordianState.opened" class="outstanding-requirements">
          <span class="accordion__sub-title-hook">
            <ent-right-button-tray *ngIf="accordianState.opened && outstandingTableConfig.data.length > 0 && !restrictChaseUser">
              <button type="button" routerLink="/requirements/{{contractNumber}}" (click)="triggerAnalytics();" entBorderButton class="resolve-btn">
                Resolve all requirements
                <i class="fas fa-arrow-circle-right" aria-hidden="true"></i>
              </button>
            </ent-right-button-tray>
          </span>
          <!-- outstanding requirements table -->
          <ent-primary-table [tableData]="outstandingTableConfig" (tableCellClick)="tableCellClick($event)"></ent-primary-table>
        </ent-panel>
        <!-- panel for completed requirements -->
        <ent-panel title="Completed requirements ({{ completedTableConfig.data.length }})">
          <!-- completed requirements table -->
          <ent-primary-table [tableData]="completedTableConfig" (tableCellClick)="tableCellClick($event)"></ent-primary-table>
        </ent-panel>
      </ent-accordion>
      <!-- accordion ends -->
    </div>
</section>
