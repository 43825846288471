import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ContractDetailsService } from 'src/app/shared/services/contract-details.service';
import { AccordianStateInterface, EntPrimaryTableConfigInterface, BRERequirement } from 'src/app/shared/models/app.model';
import { RequirementsTableConfig } from './requirement-table-config';
import { Message } from 'src/config/constants';
import { AnalyticsAppService } from 'src/app/shared/services/analytics.service';


@Component({
  selector: 'app-requirement',
  templateUrl: './requirement.component.html'
})
export class RequirementComponent implements OnInit{

  @Input()
  requirementData: BRERequirement[] = [];

  completedTableConfig: EntPrimaryTableConfigInterface = JSON.parse(JSON.stringify(RequirementsTableConfig));
  outstandingTableConfig: EntPrimaryTableConfigInterface = JSON.parse(JSON.stringify(RequirementsTableConfig));

  accordianState: AccordianStateInterface = {opened: false};
  message = Message;
  showInfoMessage: boolean = false;
  showWarningMessage: boolean = false;
  contractNumber!: number
  restrictChaseUser = false;

  constructor(private router: Router, private contractDetailsService: ContractDetailsService, private analytics: AnalyticsAppService){}

  ngOnInit(): void {
    this.loadRequirementData();
    this.completedTableConfig.column = this.completedTableConfig.column?.filter(col => col.columnKey != 'Action');
    const onlyInfoRequirements = this.requirementData?.filter((data: any) => {
      return data.RequirementSeverity === 'NIGO' && data.RequirementStatus === 'Complete';
    }).length > 0;
    if (!onlyInfoRequirements) {
      this.completedTableConfig.column = this.completedTableConfig.column?.filter(col => col.columnKey != 'Comment');
    }
    const isNIGORequirements = this.requirementData?.filter((data: any) => {
      return data.RequirementSeverity === 'NIGO' && data.RequirementStatus === 'Open';
    }).length > 0;
    if (!isNIGORequirements) {
      this.outstandingTableConfig.column = this.outstandingTableConfig.column?.filter(col => col.columnKey != 'Comment');
    }
    this.restrictChaseUsers();
    this.completedTableConfig.config.tableClass = 'completed-requirements-table';
    this.contractDetailsService.licensingViewLinkClick.subscribe(resp => {
      this.accordianState.opened = resp ? resp : false;
    });
    this.contractNumber = this.contractDetailsService.getContractNumber();
  }

  /**
   * check for the pure CHASE users and block outstanding requirements page
   *
   */
  restrictChaseUsers(): void {
    // restrict JPMC users
    this.restrictChaseUser = true;
    const strategicPartner = sessionStorage.getItem('STRATEGIC_PARTNER') ? JSON.parse(sessionStorage.getItem('STRATEGIC_PARTNER') || '') : [];
    const brokerDealerAffiliation = sessionStorage.getItem('BROKER_DEALER_AFFILIATION') ? JSON.parse(sessionStorage.getItem('BROKER_DEALER_AFFILIATION') || '') : [];
    const allTinsFirmNames = sessionStorage.getItem('ALL_TINS_FIRMNAMES') ? JSON.parse(sessionStorage.getItem('ALL_TINS_FIRMNAMES') || ''): [];
    if ((this.checkForChaseValue(strategicPartner) || this.checkForChaseValue(brokerDealerAffiliation) || this.checkForChaseValue(allTinsFirmNames)) ||
        (strategicPartner?.length === 0 && brokerDealerAffiliation?.length === 0 && allTinsFirmNames?.length === 0)) {
      this.restrictChaseUser = false;
    }
    if (this.restrictChaseUser) {
      this.outstandingTableConfig.column = this.outstandingTableConfig.column?.filter(col => col.columnKey != 'Action' && col.columnKey != 'Comment');
      this.outstandingTableConfig.config.tableClass = 'outstanding-requirements-table hide-col';
      this.completedTableConfig.column = this.completedTableConfig.column?.filter(col => col.columnKey != 'Comment');
    }
  }

  /**
   * check for CHASE value and return the flag
   *
   */
  checkForChaseValue(data: any): boolean {
    let restrictChaseUser = false;
    data?.forEach((element: string) => {
      if (element?.toLowerCase() !== 'chase') {
        restrictChaseUser = true;
        return;
      }
    });
    return restrictChaseUser;
  }

  /**
   * Saves the 'opened' state of the outstanding requirements accordian to the accordianState property
   *
   * @param event stateChange event fired by the accordian component
   */
  stateChange(event: any) {
    if (event.elRef.nativeElement.classList.contains('outstanding-requirements')) {
      this.accordianState.opened = event.opened;
    }
  }

  /**
   * Sorts the requirements data passed from the parent component into the outstanding and completed tables
   * Sets visibiity of warning / info messages for outstanding requirements
   */
  loadRequirementData(): void {
    this.requirementData?.forEach(r => {
      if (r.RequirementStatus?.toLowerCase() === 'open') {
        this.outstandingTableConfig.data.push(r);
        if (r.RequirementSeverity === "INFO"){
          this.showInfoMessage = true;
        } else{
          this.showWarningMessage = true;
        }
      } else {
        this.completedTableConfig.data.push(r);
      }
    });
  }

  /**
   * Navigates to /requirements page when hyperlinks in the table are clicked
   *
   * @param e tableCellClick event fired by the table component
   */
  tableCellClick(e: any): void {
    if (e.colKey === 'Action' || e.colKey === 'Comment') {
      this.router.navigate(['/requirements', this.contractNumber], { fragment: `form-${e.data?.ID}` });
      const clickedText = e?.e?.srcElement?.innerText;
      this.analytics.logAnalytics({
        event_action: 'link',
        event_name: this.getEventName(clickedText, e),
        event_type: 'pending tool - annuity',
        event_status: 'outstanding requirements'
      })
    }
  }

  /**
   * get event name for analytics
   *
   * @param clickedText from table col
   *
   * @param event of table clicked cell
   *
   * @returns string
   */
  getEventName(clickedText: string, e: any): string {
   if (clickedText === 'RESOLVE') {
    return `resolve ${e.data?.RequirementText}`;
   }
   if (clickedText === 'REVIEW') {
    return `review ${e.data?.RequirementText}`;
   }
   return `view comments ${e.data?.RequirementText}`;
  }

  triggerAnalytics() {
    this.analytics.logAnalytics({
        event_action: 'button',
        event_name: 'resolve all requirements',
        event_type: 'pending tool - annuity',
        event_status: 'outstanding requirements'
    })
  }

}
